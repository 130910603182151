<template>
  <div class="page-title">Quick Quote</div>
  Looking for an easy way to estimate the costs of a project? Look no further than QuickQuote! With our user-friendly
  tool, you can quickly get a sense of the scope and potential costs of any task. Simply input your project details, and
  our system will provide you with an accurate estimate of the hours, workers, and total cost. Plus, with the option to
  send your quote directly to your email, you can easily share your estimate with team members and clients. Save time
  and stay organized with QuickQuote. Get started today!

  <hr class="mb-5 mt-5" />

  <form class="my-5">
    <table class="table table-auto w-full">
      <tr class="font-bold">
        <td>Summary</td>
        <td>Base Fee (DKK)</td>
        <td>Hours</td>
        <td>Workers</td>
        <td>Total</td>
      </tr>
      <tr v-for="(quote, index) in quotes" :key="index">
        <td>
          <input v-model="quote.summary" class="input-text" placeholder="Summary" type="text" />
        </td>

        <td>
          <input v-model="quote.baseFee" class="input-text" placeholder="Base Fee" type="text"
            @change="updateSubTotal(index)" />
        </td>

        <td>
          <input v-model="quote.hours" class="input-text" placeholder="Hours" type="number"
            @change="updateSubTotal(index)" />
        </td>

        <td>
          <input v-model="quote.workers" class="input-text" placeholder="Workers" type="number"
            @change="updateSubTotal(index)" />
        </td>

        <td>
          <input v-model="quote.subTotal" class="input-text" disabled placeholder="Total" readonly type="number" />
        </td>

        <td>
          <button class="btn-danger" type="button" @click="RemoveEntryAt(index)">Remove</button>
        </td>
      </tr>
    </table>

    <button class="btn-secondary" type="button" @click="AddMoreEntries()">Add More Entries</button>
  </form>

  <div class="card p-5">
    <table class="table">
      <tr>
        <th class="text-left">Workers</th>
        <td class="text-right pl-5">{{ totals.workers }}</td>
      </tr>
      <tr>
        <th class="text-left">Hours</th>
        <td class="text-right pl-5">{{ totals.hours }}</td>
      </tr>
      <tr>
        <th class="text-left">Total</th>
        <td class="text-right pl-5">{{ totals.total }}</td>
      </tr>
    </table>
  </div>

  <div v-if="message" class="alert-info">{{ message }}</div>

  <div v-if="readyToSendEmail" class="card p-5 mt-5">
    <h3 class="font-bold mb-2">Send quote to emails inbox</h3>
    <input v-model="emails" class="input-text" name="emails" placeholder="Comma seperated list of e-mails"
      type="text" />
    <button class="btn-primary" type="button" @click="SendQuoteToEmail()">Send to email</button>
  </div>

  <div ref="tableView" class="hidden">
    <table style="border: solid 1px black;">
      <tr style="border: solid 1px black;">
        <th style="border: solid 1px black;">Summary</th>
        <th style="border: solid 1px black;">Base Fee (DKK)</th>
        <th style="border: solid 1px black;">Hours</th>
        <th style="border: solid 1px black;">Workers</th>
        <th style="border: solid 1px black;">Total</th>
      </tr>
      <tr v-for="(quote, index) in quotes" :key="index" style="border: solid 1px black;">
        <td style="border: solid 1px black;">{{ quote.summary || "Case #" + (index + 1) }}</td>
        <td style="border: solid 1px black;">{{ quote.baseFee }}</td>
        <td style="border: solid 1px black;">{{ quote.hours }}</td>
        <td style="border: solid 1px black;">{{ quote.workers }}</td>
        <td style="border: solid 1px black;">{{ quote.subTotal }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "../mixins/UtilityFunctions";

export default {
  name: "QuickQuote",
  data() {
    return {
      message: "",
      readyToSendEmail: false,
      emails: "",
      totals: {
        total: 0,
        hours: 0,
        workers: 0,
      },
      quotes: [
        {
          summary: "",
          baseFee: 200,
          hours: null,
          workers: 1,
          subTotal: null,
        },
      ]
    }
  },
  mixins: [UtilityFunctions],
  methods: {
    SendQuoteToEmail() {
      if (this.emails.trim() == "") {
        return false;
      }

      let failed_emails = "";
      let recipients = [];
      this.emails.split(',').forEach(email => {
        if (!this.isValidEmail(email)) {
          failed_emails += email;
        } else {
          recipients.push({
            email: email,
            name: email,
          });
        }
      });

      if ("" !== failed_emails) {
        alert("Invalid emails: " + failed_emails);
        return false;
      }

      let content = "<h2>Quick Quote from taskmaster.dk</h2>";
      content += this.$refs.tableView.innerHTML;

      let body = {
        content: content,
        recipients: recipients
      };

      axios
        .post(process.env.VUE_APP_API_URL + "misc/emails", body, this.getCredentialBasicHeaders())
        .then(res => {
          if (res.status !== 200) {
            throw 'Something failed while trying to send a quick quote email!';
          }

          this.message = 'Email sent';
        })
        .catch(error => {
          this.message = error.response.data.data;
          this.forceSignOut(error.response.data.code);
        });
    },
    updateGrandTotal() {
      this.readyToSendEmail = false;
      this.totals.total = 0;
      this.totals.hours = 0;
      this.totals.workers = 0;

      this.quotes.forEach(item => {
        this.totals.total += parseInt((item.subTotal ?? "0"));
        this.totals.hours += parseInt((item.hours ?? "0"));
        this.totals.workers += parseInt((item.workers ?? "0"));
      });

      if (this.totals.total > 0) {
        this.readyToSendEmail = true;
      }
    },
    AddMoreEntries() {
      this.quotes.push({
        summary: "",
        baseFee: 200,
        hours: null,
        workers: 1,
        subTotal: null,
      });

      this.updateGrandTotal();
    },
    RemoveEntryAt(index) {
      this.quotes.splice(index, 1);

      this.updateGrandTotal();
    },
    updateSubTotal(index) {
      let quote = this.quotes.at(index);
      quote.subTotal = quote.baseFee * (quote.hours * quote.workers);

      this.updateGrandTotal();
    }
  }
}
</script>

<style scoped>

</style>