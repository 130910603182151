<template>
	<div class="container">
		<h2 class="page-title">View task</h2>
	</div>
</template>
  
<script>
export default {
	name: "ViewTask",
};
</script>
  