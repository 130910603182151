<template>
  <div class="container">
    <h2 class="page-title">Create project</h2>
    <form @submit.prevent="createProject">
      <div>
        <label class="text-bold block">Name</label>
        <input v-model="name" class="input-text" placeholder="Name" required type="text" />
      </div>

      <div v-if="error" class="alert-error">{{ error }}</div>

      <div class="mt-5">
        <input class="btn-primary" name="submit-create" required type="submit" value="Create" />
      </div>
    </form>

    <div v-if="projects" class="mt-5">
      <h2 class="page-title">All my projects</h2>
      <div class="card mb-2" v-for="project in projects" :key="project.uid">
        {{ toDate(project.created, true) }}
        <router-link class="btn-primary-inline-sm" :to="'/task/create/' + project.uid">
          <PlusIcon class="icon-inline-white"></PlusIcon> Task
        </router-link>
        <router-link class="btn-primary-inline-sm" :to="'/project/edit/' + project.uid">
          <CogIcon class="icon-inline-white"></CogIcon> Edit
        </router-link>
        <button type="button" class="btn-danger-inline-sm" @click="deletePromt(project.uid)">
          <MinusIcon class="icon-inline-white"></MinusIcon> Delete
        </button>
        {{ project.name }}
      </div>
    </div>
  </div>
</template>

<script>
import Projects from "@/mixins/Projects";
import Time from "@/mixins/Time";
import UtilityFunctions from "@/mixins/UtilityFunctions";
import { PlusIcon, MinusIcon, CogIcon } from "@heroicons/vue/solid";
import axios from "axios";

export default {
  name: "CreateProject",
  mixins: [UtilityFunctions, Time, Projects],
  components: { PlusIcon, MinusIcon, CogIcon },
  data() {
    return {
      name: '',
      content: '',
      error: '',
      projects: [],
    }
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.redirect('/home');
    }

    this.listAllProjects();
  },
  methods: {
    deletePromt(uid) {
      if (confirm("Delete Project?")) {
        axios
          .delete(process.env.VUE_APP_API_URL + "project/manage?project_uid=" + uid, {
            headers: this.getCredentialHeaders()
          })
          .then(res => {
            if (res.status !== 200 && res.status !== 400) {
              throw 'Something failed while trying to delete the project!';
            }

            this.refresh('/project/create');
          })
          .catch(error => {
            this.error = error.response.data.data;
            this.forceSignOut(error.response.data.code);
          });
      }
    }
  }
};
</script>
