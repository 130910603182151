<template>
    <div class="container">
        <h2 class="page-title">Edit task</h2>
        <form @submit.prevent="submitForm">
            <div>
                <label class="text-bold block">Title *</label>
                <input v-model="title" class="input-text" placeholder="Title (Required)" required type="text"/>
            </div>

            <div class="my-5">
                <label class="text-bold block">Priority *</label>
                <select v-model="priority" class="input-text" name="priority">
                    <option v-for="_priority in metaDataPriorities" :key="_priority.id" v-bind:value="_priority.id"
                            required>
                        {{ _priority.name }}
                    </option>
                </select>
            </div>

            <div class="my-5">
                <label class="text-bold block">Project *</label>
                <select v-model="projectUID" class="input-text" name="projectUID">
                    <option v-for="project in projects" :key="project.uid" v-bind:value="project.uid" required>
                        {{ project.name }}
                    </option>
                </select>
            </div>

            <div class="my-5">
                <label class="text-bold block">Description</label>
                <tinyEditor v-model="editorContent"/>
            </div>

            <div v-if="error" class="alert-error">{{ error }}</div>

            <div class="mt-5">
                <input class="btn-primary" name="submit-create" required type="submit" value="Edit"/>
            </div>
        </form>
    </div>
</template>

<script>
import tinyEditor from "@/components/tinyEditor";
import Projects from "@/mixins/Projects";
import Time from "@/mixins/Time";
import UtilityFunctions from "@/mixins/UtilityFunctions";
import APIMeta from "@/mixins/APIMeta";
import {ref} from 'vue';

export default {
    name: "CreateTask",
    mixins: [UtilityFunctions, Time, Projects, APIMeta],
    components: {tinyEditor},
    data() {
        return {
            status: null,
            priority: null,
            projects: [],
            currentTask: null,
            title: '',
            error: '',
            projectUID: null,
            metaDataStatuses: [],
            metaDataPriorities: [],
        }
    },
    async mounted() {
        if (!this.isLoggedIn) {
            this.redirect('/home');
            return;
        }

        // Set project uid
        this.projectUID = this.$route.params.project_uid ?? this.getCurrentProjectUid;

        // Fetch projects
        await this.listAllProjects();

        // Fetch meta data
        const data = await this.fetchAllStatus();
        this.metaDataStatuses = data.metaDataStatuses;
        this.metaDataPriorities = data.metaDataPriorities;

        // Fetch task
        const res = await this.fetchTask(this.projectUID, this.$route.params.task_uid);
        this.currentTask = res.data.data;
        this.title = this.currentTask.title;
        this.editorContent = this.currentTask.description;

        // get priority id from current task priority
        this.priority = this.currentTask.priority.id;
    },
    setup() {
        const editorContent = ref('');
        return {
            editorContent,
        }
    },
    methods: {
        async submitForm() {
            // get priority name from this.priority
            let priority = this.metaDataPriorities.find(priority => priority.id === this.priority).name;

            const res = await this.updateTask(this.projectUID, this.$route.params.task_uid, this.title, this.editorContent, priority);
            if (res.status === 200) {
                this.redirect(`/task/listing/${this.projectUID}`);
            } else {
                this.error = res.data.message;
            }
        }
    }
};
</script>
  