<template>
    <div class="container">
        <div v-if="tasks && tasks.length && currentProject && currentProject.uid === projectUID">
            <h2 class="page-title">Task for {{ currentProject.name }}</h2>
            <div v-for="task in tasks" :key="task.uid" class="border p-5">
                <div class="float-right">
                    <router-link class="btn-primary-inline-sm" :to="'/task/edit/' + projectUID + '/' + task.uid ">
                        <CogIcon class="icon-inline-white"></CogIcon>
                    </router-link>

                    <button class="btn-primary-inline-sm" @click="deleteTask(task.uid)">
                        <TrashIcon class="icon-inline-white"></TrashIcon>
                    </button>
                </div>

                <span class="text-xl font-bold cursor-pointer" @click="task.showDescription = !task.showDescription">
                    {{ task.title }}
                </span>

                <div v-if="task.showDescription" class="border mb-1 mt-5 p-1 text-xs">
                    <div v-html="task.description"></div>
                </div>

                <div class="block border mb-1 mt-5 p-1 bg-gray-200 text-xs">
                    {{ task.priority.name }}
                    {{ task.status.name }}
                    <span :title="task.created">{{ getTimeAgoString(task.created) }}</span>
                </div>
            </div>
        </div>
        <div v-else class="text-center card">
            <h2 class="text-xl font-bold pt-5">No task found for this project</h2>
            <hr class="my-5"/>
            <router-link class="btn-primary-inline-sm" :to="'/task/create/' + projectUID">
                <PlusIcon class="icon-inline-white"></PlusIcon>
                Task
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "@/mixins/UtilityFunctions";
import Projects from "@/mixins/Projects";
import Time from "@/mixins/Time";
import {CogIcon, PlusIcon, TrashIcon} from "@heroicons/vue/solid";

export default {
    name: "ViewAllTaskForProject",
    mixins: [UtilityFunctions, Projects, Time],
    components: {PlusIcon, CogIcon, TrashIcon},
    data() {
        return {
            error: '',
            currentProject: null,
            projectUID: null,
            tasks: [],
        }
    },
    mounted() {
        this.projectUID = this.$route.params.project_uid ? this.$route.params.project_uid : this.getCurrentProjectUid;

        this.fetchOneProjectByUID(this.projectUID);
        this.listTasks(this.projectUID);
    },
    methods: {
        deleteTask(taskUID) {
            // confirm before deleting
            if (!confirm('Are you sure you want to delete this task?')) {
                return;
            }

            axios
                .delete(process.env.VUE_APP_API_URL + "task/manage/?project_uid=" + this.projectUID + "&task_uid=" + taskUID, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to delete task!';
                    }

                    // remove from the current list
                    this.tasks = this.tasks.filter(task => task.uid !== taskUID);
                })
                .catch(error => {
                    this.error = error.response.data.data;
                });
        },
        listTasks(projectUID) {
            axios
                .get(process.env.VUE_APP_API_URL + "task/fetchAll?project_uid=" + projectUID, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to fetch all tasks!';
                    }

                    this.tasks = res.data.data;
                })
                .catch(error => {
                    this.error = error.response.data.data;
                });
        }
    }
};
</script>
  