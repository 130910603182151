<template>
	<div class="container">
		<div class="error" v-if="error">{{ error }}</div>
		<h1 class="page-title">All Blog Posts</h1>
		<div v-if="blogPosts">
			<div v-for="(blogPost, index) in blogPosts" :key="index" class="card mb-5" :title="blogPost.created">
				<router-link class="text-red-500 underline" :to="'/content/post/' + blogPost.uid">{{
					blogPost.title
				}}</router-link>
				<br />
				<small>By {{ blogPost.author_name }} Created {{ toDate(blogPost.created) }}</small>
			</div>
		</div>
		<div v-else>Loading...</div>
	</div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "@/mixins/UtilityFunctions";
import Time from "@/mixins/Time";

export default {
	name: "BlogListing",
	mixins: [UtilityFunctions, Time],
	data() {
		return {
			blogPosts: [],
			error: ''
		}
	},
	mounted() {
		this.fetchBlogPosts()
	},
	methods: {
		fetchBlogPosts() {
			axios
				.get(process.env.VUE_APP_API_URL + "content/fetchAll", {
					headers: this.getCredentialBasicHeaders()
				})
				.then(res => {
					if (res.status !== 200) {
						throw 'Something failed while trying to fetch web content!';
					}

					this.blogPosts = res.data.data;
				})
				.catch(error => {
					this.error = error.response.data.data;
				});
		},
	}
};
</script>
