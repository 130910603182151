<template>
	<div class="container">
		<h1 class="page-title">Profile</h1>

		<div v-if="profileObject.profile">
			<div class="mb-5">
				<label for="first_name">First Name</label><br />
				{{ profileObject.profile.first_name }}
			</div>

			<div class="mb-5">
				<label for="last_name">Last Name</label><br />
				{{ profileObject.profile.last_name }}
			</div>

			<div class="mb-5">
				<label for="email">Email</label><br />
				{{ profileObject.profile.email }}
			</div>

			<div class="mb-5">
				<label for="created">Created</label><br />
				{{ profileObject.profile.created }}
			</div>

			<div class="mb-5">
				<label for="active">Active</label><br />
				{{ profileObject.profile.active ? "Yes" : "Pending" }}
			</div>

			<div class="mt-5">
				<button class="btn-danger" @click="signout">Logout</button>
			</div>
		</div>
		<div v-else>Loading...</div>

		<div v-if="error" class="alert-error">{{ error }}</div>
	</div>
</template>

<script>
import UtilityFunctions from "@/mixins/UtilityFunctions";
import Accounts from "@/mixins/Accounts";

export default {
	name: "Profile",
	mixins: [UtilityFunctions, Accounts],
	data() {
		return {
			error: '',
			userUid: this.$route.params.uid,
		}
	},
	mounted() {
		this.fetchProfile(this.userUid);
	},
	methods: {
		signout() {
			this.logout()
		}
	}
};
</script>
