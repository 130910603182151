<template>
  <div class="container">
    <div class="error" v-if="error">{{ error }}</div>
    <div v-if="content">
      <h2 class="page-title">{{ content.title }}</h2>

      <div v-html="content.content"></div>

      <div class="mt-5 text-xs text-red">
        By {{ content.author.full_name }} @ {{ content.created }}
      </div>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "@/mixins/UtilityFunctions.js";

export default {
  name: "BlogPost",
  mixins: [UtilityFunctions],
  data() {
    return {
      content: '',
      error: '',
      blogUid: this.$route.params.uid,
    }
  },
  mounted() {
    this.fetchBlogPost()
  },
  methods: {
    fetchBlogPost() {
      axios
        .get(process.env.VUE_APP_API_URL + "content/post?uid=" + this.blogUid, {
          headers: this.getCredentialHeaders()
        })
        .then(res => {
          if (res.status !== 200) {
            throw 'Something failed while trying to fetch web content!';
          }

          this.content = res.data.data;
        })
        .catch(error => {
          this.error = error.response.data.data;
        });
    },
  }
};
</script>
