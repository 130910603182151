<template>
  <div class="container">
    <h2 class="page-title">Create web content</h2>
    <form @submit.prevent="createContent">
      <div>
        <label class="text-bold block">Title</label>
        <input v-model="title" class="input-text" placeholder="Title" required type="text" />
      </div>

      <div class="mt-5">
        <label class="text-bold block">Content</label>
        <tinyEditor v-model="editorContent" />
      </div>

      <div v-if="error" class="alert-error">{{ error }}</div>

      <div class="mt-5">
        <input class="btn-primary" name="submit-create" required type="submit" value="Create" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "@/mixins/UtilityFunctions.js";
import tinyEditor from "@/components/tinyEditor";
import { ref } from 'vue';

export default {
  name: "CreateBlogPost",
  mixins: [UtilityFunctions],
  components: {
    tinyEditor
  },
  data() {
    return {
      title: '',
      content: '',
      error: ''
    }
  },
  setup() {
    const editorContent = ref('');
    return {
      editorContent,
    }
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.redirect('/home');
    }
  },
  methods: {
    createContent() {
      let body = {
        title: this.title,
        content: this.editorContent
      };

      axios
        .post(process.env.VUE_APP_API_URL + "content/post", body, {
          headers: this.getCredentialHeaders()
        })
        .then(res => {
          if (res.status !== 200) {
            throw 'Something failed while trying to create a new web content!';
          }

          this.error = '';

          this.title = '';
          this.content = '';

          this.refresh('/content/post/' + res.data.data);
        })
        .catch(error => {
          this.error = error.response.data.data;
          this.forceSignOut(error.response.data.code);
        });
    },
  }
};
</script>
