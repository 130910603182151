<template>
    <div class="container">
        <h2 class="page-title">Register new account</h2>

        <form @submit.prevent="register">
            <div>
                <label for="firstName">First Name:</label>
                <input class="input-text" type="text" id="firstName" v-model="firstName" required pattern=".{1,}" />
            </div>

            <div>
                <label for="lastName">Last Name:</label>
                <input class="input-text" type="text" id="lastName" v-model="lastName" required pattern=".{1,}" />
            </div>

            <div>
                <label for="email">Email:</label>
                <input class="input-text" type="email" id="email" v-model="email" required pattern=".{1,}" />
            </div>

            <div>
                <label for="password">Password:</label>
                <input class="input-text" type="password" id="password" v-model="password" pattern=".{1,}" required />
            </div>

            <div>
                <label for="confirmPassword">Confirm Password:</label>
                <input class="input-text" type="password" id="confirmPassword" pattern=".{1,}" v-model="confirmPassword"
                    required />
            </div>

            <div v-if="error" class="alert-error">{{ error }}</div>

            <div>
                <button class="btn-primary">Register</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import UtilityFunctions from "@/mixins/UtilityFunctions.js";

export default {
    name: 'NewUser',
    mixins: [UtilityFunctions],
    data() {
        return {
            error: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: ""
        };
    },
    mounted() {
        if (this.isLoggedIn) {
            this.redirect('/home');
        }
    },
    methods: {
        register() {
            if (this.password !== this.confirmPassword) {
                // Handle password mismatch
                this.error = "Passwords to not match!";
                return;
            }

            // Send registration data to backend
            let body = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                phone: this.phone,
                passwords: [this.password, this.confirmPassword]
            };

            axios
                .post(process.env.VUE_APP_API_URL + "account/create", body, this.getCredentialBasicHeaders())
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to create a new account!';
                    }

                    this.error = '';

                    this.first_name = '';
                    this.last_name = '';
                    this.email = '';
                    this.phone = '';
                    this.password = '';
                    this.confirmPassword = '';

                    localStorage.setItem(this.currentUserKey, JSON.stringify(res.data));

                    this.refresh('/home');
                })
                .catch(error => {
                    this.error = error.response.data.data
                });
        }
    }
};
</script>
