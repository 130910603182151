import {createRouter, createWebHistory} from 'vue-router';
import Home from '@/views/Home.vue';
import PageNotExist from '@/views/PageNotExist.vue';
import NewUser from '@/views/account/NewUser';
import Login from '@/views/account/Login';
import Profile from '@/views/account/Profile';
import BlogPost from '@/views/blog/BlogPost';
import CreateBlogPost from '@/views/blog/CreateBlogPost';
import CreateProject from '@/views/project/CreateProject';
import CreateTask from '@/views/task/CreateTask';
import ViewTask from '@/views/task/ViewTask';
import ViewAllTaskForProject from '@/views/task/ViewAllTaskForProject';
import EditTask from '@/views/task/EditTask';
import EditProject from '@/views/project/EditProject';
import BlogListing from '@/views/blog/BlogListing';
import QuickQuoteView from '@/views/QuickQuoteView';

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/quick-quote',
        name: 'QuickQuote',
        component: QuickQuoteView
    },
    {
        path: '/account/new-user',
        name: 'NewUser',
        component: NewUser
    },
    {
        path: '/account/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/account/user/:uid',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/content/create',
        name: 'CreateBlogPost',
        component: CreateBlogPost
    },
    {
        path: '/project/create',
        name: 'CreateProject',
        component: CreateProject
    },
    {
        path: '/project/edit/:uid',
        name: 'EditProject',
        component: EditProject
    },
    {
        path: '/task/create/:project_uid?',
        name: 'CreateTask',
        component: CreateTask
    },
    {
        path: '/task/:task_uid?',
        name: 'ViewTask',
        component: ViewTask
    },
    {
        path: '/task/listing/:project_uid?',
        name: 'ViewAllTaskForProject',
        component: ViewAllTaskForProject
    },
    {
        path: '/task/edit/:project_uid/:task_uid',
        name: 'EditTask',
        component: EditTask
    },
    {
        path: '/content/listing/',
        name: 'BlogListing',
        component: BlogListing
    },
    {
        path: '/content/post/:uid',
        name: 'BlogPost',
        component: BlogPost
    },
    {
        path: '/404',
        name: 'PageNotExist',
        component: PageNotExist
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/404',
    }
];

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
