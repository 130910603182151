import UtilityFunctions from "./UtilityFunctions";
import axios from "axios";

export default {
    name: 'Accounts',
    mixins: [UtilityFunctions],
    data() {
        return {
            profileObject: {},
            error: ''
        }
    },
    methods: {
        logout() {
            let headers = this.getCredentialHeaders();

            axios
                .delete(process.env.VUE_APP_API_URL + "account/logout", headers)
                .then(res => {
                    if (res.status !== 200 && res.status !== 400) {
                        throw 'Something failed while trying to log user out!';
                    }

                    this.profile = {};

                    this.removeLoginData();
                    this.refresh('/home');
                })
                .catch(error => {
                    this.error = error.response.data.data;
                    this.forceSignOut(error.response.data.code);
                });
        },
        fetchProfile(uid) {
            if (!uid) {
                this.error = "UID is required to fetch profile data for a user!";
                return false;
            }

            this.fetchProfile = {};

            let headers = this.getCredentialHeaders();

            axios
                .get(process.env.VUE_APP_API_URL + "account/profile?uid=" + uid, headers)
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to fetch profile data!';
                    }

                    this.profileObject = res.data.data;
                })
                .catch(error => {
                    this.error = error.response.data.data;
                    this.forceSignOut(error.response.data.code);
                });
        },
    },
}
