<template>
  <div class="container">
    <h2 class="page-title">Login</h2>
    <form @submit.prevent="login">
      <div>
        <label class="text-bold block">Email</label>
        <input v-model="email" class="input-text" placeholder="Email" required type="text" />
      </div>

      <div class="mt-5">
        <label class="text-bold block">Password</label>
        <input v-model="password" class="input-text" placeholder="Password" required type="password" />
      </div>

      <div v-if="error" class="alert-error">{{ error }}</div>

      <div class="mt-5">
        <input class="btn-primary" name="submit-login" required type="submit" value="Login" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import UtilityFunctions from "@/mixins/UtilityFunctions.js";

export default {
  name: "Login",
  mixins: [UtilityFunctions],
  data() {
    return {
      email: '',
      password: '',
      error: ''
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.redirect('/home');
    }
  },
  methods: {
    login() {
      this.error = "";

      let body = {
        email: this.email,
        password: this.password,
      };

      axios
        .post(process.env.VUE_APP_API_URL + "account/login", body, this.getCredentialBasicHeaders())
        .then(res => {
          if (res.status !== 200) {
            throw 'Something failed while trying to login!';
          }

          this.error = '';
          this.email = '';
          this.password = '';

          localStorage.setItem(this.currentUserKey, JSON.stringify(res.data));

          this.refresh('/home');
        })
        .catch(error => {
          this.error = error.response.data.data;
        });
    },
  }
};
</script>
