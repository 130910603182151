
import UtilityFunctions from "./UtilityFunctions";
import axios from "axios";

export default {
    name: "APIMeta",
    mixin: [UtilityFunctions],
    data: function () {
        return {
            metaDataStatuses: [],
            metaDataPriorities: [],
            error: null,
        };
    },
    methods: {
        fetchAllStatus() {
            var cachedData = localStorage.getItem("metaData");
            if (cachedData) {
                var data = JSON.parse(cachedData);
                this.metaDataStatuses = data.metaDataStatuses;
                this.metaDataPriorities = data.metaDataPriorities;
                return Promise.resolve(data);
            }

            var self = this;
            return axios.get(process.env.VUE_APP_API_URL + "misc/fetchMetaData", {
                headers: this.getCredentialHeaders(),
            })
                .then(function (res) {
                    if (res.status !== 200) {
                        throw new Error("Something failed while trying to fetch meta data!");
                    }

                    self.metaDataStatuses = res.data.data.statuses;
                    self.metaDataPriorities = res.data.data.priorities;

                    var dataToCache = { metaDataStatuses: self.metaDataStatuses, metaDataPriorities: self.metaDataPriorities };
                    localStorage.setItem("metaData", JSON.stringify(dataToCache));

                    return { metaDataStatuses: self.metaDataStatuses, metaDataPriorities: self.metaDataPriorities };
                })
                .catch(function (error) {
                    self.error = error.response ? error.response.data.data : error.message;
                    throw error;
                });
        },
    },
}
