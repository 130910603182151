import DOMPurify from 'dompurify';

export default {
    name: 'UtilityFunctions',
    data() {
        return {
            currentUserKey: 'current-user',
        }
    },
    watch: {
        tokenExpiration: {
            immediate: true,
            handler() {
                this._checkTokenExpiration();
            }
        }
    },
    computed: {
        currentUserObject() {
            let object_string = localStorage.getItem(this.currentUserKey);
            if (!object_string) {
                return false;
            }

            return JSON.parse(object_string);
        },
        bearerToken() {
            return this.currentUserObject ? this.currentUserObject.data.access.token : false;
        },
        isLoggedIn() {
            return !!this.currentUserObject;
        },
        getCurrentProjectUid(){
            return this.currentUserObject ? this.currentUserObject.data.defaults.project_uid : null;
        },
    },
    methods: {
        isValidEmail(email){
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        displayText(html) {
            return DOMPurify.sanitize(html);
        },
        getCredentialBasicHeaders() {
            return {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            };
        },
        getCredentialHeaders() {
            return {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                'withCredentials': true,
                'Bearer': this.bearerToken,
            };
        },
        refresh(route = '/profile') {
            window.location.href = route;
        },
        redirect(route = '/profile') {
            this.$router.push(route);
        },
        _checkTokenExpiration() {
            let pay = this.getPayload(this.bearerToken);
            if (!pay) {
                return;
            }

            if (this.isGreaterThanCurrentTime(pay.exp)) {
                setTimeout(this._checkTokenExpiration, 60000);
                return;
            }

            this.forceSignOut('UNAUTHORIZED');
        },
        isGreaterThanCurrentTime(timestamp) {
            const currentTime = Math.floor(Date.now() / 1000);
            return timestamp > currentTime;
        },
        getPayload(token) {
            if (!this._payloads) {
                this._payloads = {};
            }

            if (token) {
                if (!this._payloads[token]) {
                    let base64Url = token.split('.')[1];
                    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    let json_payload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));

                    this._payloads[token] = JSON.parse(json_payload);
                }
            }

            return this._payloads[token];
        },
        forceSignOut(code) {
            if (code == 'UNAUTHORIZED') {
                this.removeLoginData();
                this.refresh('/home');
            }
        },
        removeLoginData() {
            localStorage.removeItem(this.currentUserKey);
        }
    },
}
