<template>
	<div class="container">
		<h2 class="page-title">Create task</h2>
		<form @submit.prevent="submitForm">
			<div>
				<label class="text-bold block">Title *</label>
				<input v-model="title" class="input-text" placeholder="Title (Required)" required type="text" />
			</div>

			<div class="my-5">
				<label class="text-bold block">Priority *</label>
				<select v-model="priority" class="input-text" name="priority">
					<option v-for="priority in metaDataPriorities" :key="priority.uid" required>
						{{ priority.name }}
					</option>
				</select>
			</div>

			<div class="my-5">
				<label class="text-bold block">Project *</label>
				<select v-model="projectUID" class="input-text" name="projectUID">
					<option v-for="project in projects" :key="project.uid" v-bind:value="project.uid" required>
						{{ project.name }}
					</option>
				</select>
			</div>

			<div class="my-5">
				<label class="text-bold block">Description</label>
				<tinyEditor v-model="editorContent" />
			</div>

			<div v-if="error" class="alert-error">{{ error }}</div>

			<div class="mt-5">
				<input class="btn-primary" name="submit-create" required type="submit" value="Create" />
			</div>
		</form>
	</div>
</template>
  
<script>
import tinyEditor from "@/components/tinyEditor";
import Projects from "@/mixins/Projects";
import Time from "@/mixins/Time";
import UtilityFunctions from "@/mixins/UtilityFunctions";
import APIMeta from "@/mixins/APIMeta";
import {ref} from 'vue';

export default {
	name: "CreateTask",
	mixins: [UtilityFunctions, Time, Projects, APIMeta],
	components: { tinyEditor },
	data() {
		return {
			priority: '',
			title: '',
			error: '',
			projectUID: null,
			tasks: [],
			projects: [],
		}
	},
	mounted() {
		if (!this.isLoggedIn) {
			this.redirect('/home');
		}

		this.projectUID = !this.$route.params.project_uid ? this.getCurrentProjectUid : null;

		this.fetchAllStatus().then(function (data) {
			this.metaDataStatuses = data.metaDataStatuses;
			this.metaDataPriorities = data.metaDataPriorities;
		});

		this.listAllProjects();
	},
	setup() {
		const editorContent = ref('');
		return {
			editorContent,
		}
	},
	methods: {
		submitForm() {
			this.createTask(this.projectUID, this.title, this.editorContent, this.priority);
		},
	}
};
</script>
  