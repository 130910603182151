<template>
	<editor v-model="editorContent" api-key="vkvm82xh30xt2bioftdaolsi0o5g0gn1cvh8zlbqgvtvgqwq" :init="{
		height: 500,
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount'
		],
		toolbar:
			'undo redo | formatselect | bold italic backcolor | \
			alignleft aligncenter alignright alignjustify | \
			bullist numlist outdent indent | removeformat | help'
	}" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { ref } from 'vue';

export default {
	name: "tinyEditor",
	components: {
		Editor
	},
	setup() {
		const editorContent = ref('');

		return {
			editorContent,
		}
	}
}
</script>

<style scoped>

</style>