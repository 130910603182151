export default {
    methods: {
        getTimeAgoString(datetime) {
            const now = new Date();
            const date = new Date(datetime);
            if (isNaN(date)) {
                return 'Invalid date';
            }

            const diff = now.getTime() - date.getTime();
            const seconds = Math.floor(diff / 1000);

            if (seconds < 60) {
                return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
            }

            const minutes = Math.floor(seconds / 60);
            if (minutes < 60) {
                return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
            }

            const hours = Math.floor(minutes / 60);
            if (hours < 24) {
                return `${hours} hour${hours === 1 ? '' : 's'} ago`;
            }

            const days = Math.floor(hours / 24);
            if (days < 30) {
                return `${days} day${days === 1 ? '' : 's'} ago`;
            }

            // return the original datetime string for dates older than 30 days
            return datetime;
        },
        currentDatetime() {
            let date = new Date();
            return date.getDate().toString().padStart(2, '0') + "-" + (1 + date.getMonth()).toString().padStart(2, '0') + "-" + date.getFullYear() + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');
        },
        toDatetime(datetime) {
            let date = new Date(datetime);
            return date.getDate().toString().padStart(2, '0') + "/" + (1 + date.getMonth()).toString().padStart(2, '0') + "/" + date.getFullYear() + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');
        },
        toTime(datetime) {
            let date = new Date(datetime);
            return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');
        },
        toDate(datetime, reserve = false) {
            let date = new Date(datetime);
            if (reserve) {
                return date.getFullYear() + "/" + date.getMonth().toString().padStart(2, '0') + "/" + date.getDate().toString().padStart(2, '0');
            }

            return date.getDate().toString().padStart(2, '0') + "/" + date.getMonth().toString().padStart(2, '0') + "/" + date.getFullYear();
        },
        toDateOnly(datetime) {
            let date = new Date(datetime);
            return date.getDate().toString().padStart(2, '0');
        },
        toMonthOnly(datetime) {
            let date = new Date(datetime);
            return date.getMonth().toString().padStart(2, '0');
        },
        toYearOnly(datetime) {
            let date = new Date(datetime);
            return date.getFullYear();
        }
    }
}