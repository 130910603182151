import UtilityFunctions from "./UtilityFunctions";
import axios from "axios";

export default {
    name: 'Projects',
    mixins: [UtilityFunctions],
    data() {
        return {
            error: '',
        }
    },
    methods: {
        updateTask(project_uid, task_uid, title, description, priority) {
            let body = {
                title: title,
                content: description,
                priority: priority
            };

            console.log(body);

            let url = process.env.VUE_APP_API_URL + "task/manage?project_uid=" + project_uid + "&task_uid=" + task_uid;
            return axios
                .put(url, body, {
                    headers: this.getCredentialHeaders()
                })
        },
        editProject(projectUID, name) {
            let body = {
                uid: projectUID,
                name: name,
            };

            axios
                .patch(process.env.VUE_APP_API_URL + "project/manage", body, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to edit a project!';
                    }

                    this.error = '';

                    this.title = '';

                    this.refresh('/project/create');
                })
                .catch(error => {
                    this.error = error.response.data.data;
                    this.forceSignOut(error.response.data.code);
                });
        },
        fetchOneProjectByUID(projectUID) {
            axios
                .get(process.env.VUE_APP_API_URL + "project/fetch?project_uid=" + projectUID, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to fetch a project!';
                    }

                    this.currentProject = res.data.data;
                })
                .catch(error => {
                    this.error = error.response.data.data;
                });
        },
        createProject() {
            let body = {
                name: this.name,
            };

            axios
                .post(process.env.VUE_APP_API_URL + "project/manage", body, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to create a new project!';
                    }

                    this.error = '';

                    this.title = '';

                    this.refresh('/project/create');
                })
                .catch(error => {
                    this.error = error.response.data.data;
                    this.forceSignOut(error.response.data.code);
                });
        },
        fetchTask(project_uid, task_uid) {
            return axios
                .get(process.env.VUE_APP_API_URL + "task/fetch?project_uid=" + project_uid + "&task_uid=" + task_uid, {
                    headers: this.getCredentialHeaders()
                })
        },
        createTask(project_uid, title, description, priority) {
            let body = {
                title: title,
                content: description,
                priority: priority
            };

            axios
                .post(process.env.VUE_APP_API_URL + "task/manage?project_uid=" + project_uid, body, {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to create a new project!';
                    }

                    this.error = '';

                    this.refresh('/task/create');
                })
                .catch(error => {
                    this.error = error.response.data.data;
                    this.forceSignOut(error.response.data.code);
                });
        },
        listAllProjects() {
            axios
                .get(process.env.VUE_APP_API_URL + "project/fetchAll", {
                    headers: this.getCredentialHeaders()
                })
                .then(res => {
                    if (res.status !== 200) {
                        throw 'Something failed while trying to fetch all projects!';
                    }

                    this.projects = res.data.data;
                })
                .catch(error => {
                    this.error = error.response.data.data;
                });
        },
    },
}
