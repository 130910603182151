<template>
  <div class="container" v-if="currentProject">
    <h2 class="page-title">Edit project</h2>
    <form @submit.prevent="saveProject">
      <div>
        <label class="text-bold block">Name</label>
        <input v-model="currentProject.name" class="input-text" placeholder="Name" required type="text" />
      </div>

      <div v-if="error" class="alert-error">{{ error }}</div>

      <div class="mt-5">
        <input class="btn-primary" name="submit-create" required type="submit" value="Edit" />
      </div>
    </form>
  </div>
  <div v-else>Loading project info...</div>
</template>

<script>
import UtilityFunctions from "@/mixins/UtilityFunctions";
import Time from "@/mixins/Time";
import Projects from "@/mixins/Projects";

export default {
  name: "CreateProject",
  mixins: [UtilityFunctions, Time, Projects],
  data() {
    return {
      projectUID: this.$route.params.uid,
      error: '',
      currentProject: null
    }
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.redirect('/home');
    }

    this.fetchOneProjectByUID(this.projectUID);
  },
  methods: {
    saveProject() {
      this.editProject(this.projectUID, this.currentProject.name);
    },
  }
};
</script>
