<template>
  <div class="bg-red-500 text-white py-16">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
      <div class="mb-8 md:mb-0">
        <h1 class="text-4xl font-bold mb-4">Welcome to Taskmaster!</h1>
        <p class="text-lg">
          Our web service offers a user-friendly interface for managing your invoicing, contacts, and clients in one
          place. You can easily send invoices, track tasks, view project progress and finances, and stay organized with
          top-notch security measures. Sign up today and experience the convenience of having everything in one place.
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="card mb-5">
      <label>Improvement</label><br />
      During our alpha phase, we aim to deliver an excellent user experience and apologize for any inconvenience
      caused by possible bugs or issues, but we appreciate your feedback and participation to refine and perfect our
      service.
    </div>

    <div class="card mb-5">
      <label>Commitment</label><br />
      Our dedication to delivering excellent service is evidenced by our continuous effort to improve it, through
      prompt issue resolution and consideration of feedback, as we aim to provide the best possible experience and
      work alongside our customers towards this goal.
    </div>

    <div class="card mb-5">
      <label>Opportunity</label><br />
      Don't miss the exclusive opportunity to experience our cutting-edge service and take advantage of all its
      amazing features for free during the alpha phase, as a token of our appreciation for your help in testing and
      improving it.
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
