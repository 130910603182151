<template>
  <div class="container">
    <h2 class="page-title">404</h2>
    <p class="alert-error">The is nothing to see here...</p>

    <br /><br />
    <div class="border rounded p-5">
        <strong>Version</strong><br/>
        The version of the client application that is currently being used is version <u>{{ appVersion }}</u>. This
        version of the application has been released and is available for use by the users. It is the most recent
        version of the application that has been made available to the public and includes the latest features,
        enhancements and bug fixes.

        <br/><br/>

        All versions starting with an "a" is an alpha version. This means that the application is still in the early
        stages of development and may contain bugs or unfinished features. Despite this, it is still free to use and
        available for testing and feedback.

        <br/><br/>

        <strong>Versioning strategy</strong><br/>
        When creating software updates, it is important to follow a consistent version numbering system. The convention
        we use is to increment the version number in the following format:<br/><br/>

        Major version - significant changes or new features are added,<br/>
        Minor version - minor updates and bug fixes,<br/>
        Micro version - small updates and bug fixes.<br/><br/>

        For example, 1.0.0 represents the first major release, and 1.1.0 represents the first minor update, and 1.1.1
        represents the first micro update. This helps our team and users to identify the changes and updates
        effectively.
    </div>
      <br/><br/>

      <div class="p-5 border rounded mb-5 text-center text-xl">
          <strong>Changelog</strong>
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-05-18</strong><br/>
          Fixed minor security and performance issues.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-05-16</strong><br/>
          We are excited to announce a new feature in our task management application that adds an extra layer of
          protection to prevent accidental task deletion. Starting from this release, users will be prompted to confirm
          their action before deleting tasks in the list view.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-05-09</strong><br/>
          New feature in task management app: list all tasks and edit them from the list view. Saves time and helps stay
          organized. Click "Edit" next to any task in the list view to modify description, due date, priority, etc.
          Contact support with feedback/suggestions.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-04-01</strong><br/>
          New feature added: tasks can now be added to projects for better organization and collaboration. Simply click
          "Add Task" within your project and enter task details, including name, description, due date, and assignee.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-03-09</strong><br/>
          We are excited to announce that our platform now allows users to delete projects! With this new feature, users
          can easily remove any unwanted projects from their account. To delete a project, simply navigate to the
          project's settings and click the "Delete Project" button.
          We hope this feature makes managing projects on our platform even easier for our users.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-03-09</strong><br/>
          We're excited to announce that we have added a new feature to our blog post list view: created date. This
          means that readers can now easily see when each post was published without having to click through to the
          individual post. We hope this small but useful addition will enhance the user experience on our blog.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-03-08</strong><br/>
          New feature added! You can now edit project names. Click on the project name in the view page, make your
          changes, and click save.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-03-03</strong><br/>
          With the latest update, users can now create new projects and list them, thanks to the added functionality.
          This feature can help users better manage their projects and stay organized.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-03-03</strong><br/>
          Add a utility mixin which provides methods for formatting dates and times in various ways, including getting
          the current date and time, formatting the date, time, year, month, and date-only parts of a given date/time
          string.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-02-01</strong><br/>
          Proud to announce TinyMCE support! All our blog posts are now written and formatted with ease using this
          powerful rich text editor. Enjoy improved content creation with TinyMCE!
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-30</strong><br/>
          Excited to announce a major update! You can now view all our blog posts in a single, easy-to-use list. Stay up
          to date with the latest content from our team with ease!
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-29</strong><br/>
          Introducing Quick Quote, a convenient tool for quickly getting an overview of a potential task and its scope.
          With this tool, you can easily add multiple quotes and calculate the total cost, hours and workers needed for
          a project. It also allows you to send the quote directly to your clients via email. Quick Quote makes it easy
          for you to handle projects and streamline your business operations. Try it now and see how it can help you
          save time and win more business.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-28</strong><br/>
          We are excited to announce a new feature that allows our users to display user-generated content safely on our
          platform. We have implemented a library called DOMPurify that sanitizes any HTML code before it is rendered,
          eliminating the risk of cross-site scripting (XSS) attacks. Our platform prioritizes security for our users
          and this feature is just one of the many ways we are working to improve it. We encourage our users to take
          advantage of this new feature and continue to share their creative content. Our support team is always
          available to answer any questions or concerns.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-28</strong><br/>
          We've added the capability to view individual blog posts on their own page, and added the functionality of
          redirecting to the newly created blog post page after creating a new blog post.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-28</strong><br/>
          We've implemented a new feature that checks if the user's token has expired and forces a logout if the token
          is no longer valid. This ensures that users are prompted to login again after 10 minutes of inactivity for
          enhanced security.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-28</strong><br/>
          Introducing the new feature of creating web content for general updates from our team. Stay informed and
          up-to-date with the latest news and announcements from us.
      </div>

      <div class="p-5 border rounded mb-5">
          <strong>2023-01-28</strong><br/>
          We are pleased to announce the implementation of the logout feature for our users. Additionally, we have made
          enhancements to the stability of our platform by streamlining some of the underlying logic. This will ensure a
          better user experience for our users.
      </div>

      <div class="p-5 border rounded">
          <strong>2023-01-25</strong><br/>
          A new feature for user account creation has been added. Successful account creation will automatically log the
          user in. Login functionality has been improved to support existing accounts. These updates will enhance the
          user experience by providing more options for accessing the full functionality of the application.
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "PageNotExist",
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION
    }
  }
};
</script>
