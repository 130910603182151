<template>
  <div class="block">
    <header class="p-5 bg-gray-700 border border-gray-700 text-red-500">
      <div v-if="hasAccess">
        <router-link class="m-1" :to="'/home'">Home</router-link>
        <router-link class="m-1" :to="'/account/user/' + currentUserUID">Profile</router-link>
        |
        <router-link class="m-1" :to="'/content/create/'">Blog</router-link>
        <router-link class="m-1" :to="'/project/create/'">Project</router-link>
        <router-link class="m-1" :to="'/task/create/'">Task</router-link>
        |
        <router-link class="m-1" :to="'/content/listing/'">Blog Listing</router-link>
        <router-link class="m-1" :to="'/task/listing/' + currentProjectUID">Task Listing</router-link>
        <router-link class="m-1" :to="'/quick-quote'">Quick Quote</router-link>
      </div>
      <div v-else>
        <router-link class="m-1" :to="'/home'">Home</router-link>
        <router-link class="m-1" :to="'/content/listing/'">Blog Listing</router-link>
        <router-link class="m-1" :to="'/account/new-user'">Register</router-link>
        <router-link class="m-1" :to="'/account/login'">Login</router-link>
        <router-link class="m-1" :to="'/quick-quote'">Quick Quote</router-link>
      </div>
    </header>
    <router-view />
  </div>
</template>

<script>
import UtilityFunctions from "@/mixins/UtilityFunctions";

export default {
  name: "TaskMaster",
  mixins: [UtilityFunctions],
  data() {
    return {
      hasAccess: false,
      currentUserUID: null,
      currentProjectUID: null,
    }
  },
  mounted() {
    this.hasAccess = this.isLoggedIn;
    this.currentUserUID = this.currentUserObject ? this.currentUserObject.data.profile.uid : null
		this.currentProjectUID = !this.$route.params.project_uid ? this.getCurrentProjectUid : null;
  },
};
</script>